@font-face {
    font-family: 'Inter', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
    text-decoration: none;
}

@font-face {
    font-family: 'Open Sans', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
    text-decoration: none;
}

.headerContainer {
    padding-bottom: 30%;
}

.leftSection {
    padding-left: 10%;
}
.titleHead {
    width: 626px;
    font-weight: 700;
    font-size: 64px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-top: 8%;
}

.titleSubHead {
    width: 646px;
    height: 108px;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-weight: 500;
    padding-top: 56px;
    padding-bottom: 15px;
    text-align: left;
}

.sectionButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 1%;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 300px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    z-index: 2;
} 

.sectionButton:hover {
    box-shadow: 0px 4px 0px #070707 !important;
}

.mascotContainer {
    float: right;
}

.mascotFront {
    margin-right: -5%;
    margin-top: -75%;
    height: 110vh;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}


@media screen and (max-width: 767px) {
    
    .leftSection {
        padding-left: 5%;
    }
    .titleHead {
      text-align: center;
      width: 90vw;
      font-size: 36px;
      padding-top: 10%;
    }

    .titleSubHead {
        text-align: center;
        width: 80vw;
        font-size: 14px;
        line-height: 20px;
        padding-left: 5%;
        padding-top: 16px;
    }

    .mascotContainer {
        padding-top: -22%;
    }

    .mascotFront {
        display: none;
    }
    
    .mascotFront2 {
        width: 100vw;
    }

    .sectionButton {
        display: none;
    }

    .headerContainer {
        padding-bottom: 2%;
    }

  }

@media screen and (min-width: 768px) {
    .mascotFront {
      background-image: url('../images/mascotBack.svg');
      background-repeat: no-repeat;
    }

    .mascotFront2 {
        display: none;
    }
  }
  