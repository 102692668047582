@media screen and (max-width: 767px) {
  .container {
    display: none;
  }
  .partner {
    display: none;
  }
}

.partner {
    padding-top: 4%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  .fade-in-section.is-visible {
    opacity: 1;
    transform: translateY(0);
  }