@font-face {
    font-family: 'Inter', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .valuesContainer {
        width: 100vw;
        display: block;
    }

    .vector {
        padding-bottom: 150%;
    }

    .heading {
        display: none;
    }

    .headingSmall {
        width: 250px;
        margin-bottom: 4px;
        /* BOX */
        box-sizing: border-box;
        text-align: center;
        padding: 10px 42px;
        border: 4px solid #000000;
        border-radius: 300px;
        order: 0;
        
        /* TEXT */
        font-size: 22px;
        font-weight: 600;
        margin: 0 auto;
    }

    .subHeadingSmall {
        padding-top: 5%;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .subHeading {
        display: none;
    }

    .cardContainer2 {
        grid-template-columns: 1fr;
        margin-top: 360%;
        display: block;
        justify-content: center;
        align-items: center;
        width: 90vw;
        z-index: 5;
        padding-left: 6%;
            /* position: absolute;
            margin-top: 120%;
            z-index: 5;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        
         */
    }

    .column {
        display: none;
    }

    .topLeft {
        display: none;
    }

    .topLeftSmall {
        width: 100vw;
        width: 343px;
        height: 455px;
        color: black;
        border-radius: 30px;
        border: 2px solid #000;
        background: #FFF;
        /* within card spacing */
        margin-top: 60px;
        margin-bottom: 30px;
        text-align: center;

    }

    .topLeftSmall:hover {
        box-shadow: 0px 8px 0px 0px #070707;
    }

    .titleSmall {
        color: #000;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.56px;
        margin-top: 39px;
        margin-bottom: 21px;
    }

    .descriptionSmall {
        width: 320px;
        padding-left: 10px;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
        padding-bottom: 21px;
    }

    .bottomLeft {
        display: none;
    }

    .bottomLeftSmall {
        width: 343px;
        height: 455px;
        border-radius: 30px;
        border: 2px solid #000;
        background: #FFE8ED;
        justify-content: center;
        align-items: center;
        display: inline-block;
        margin-bottom: 30px;
    }

    .bottomLeftSmall:hover {
        box-shadow: 0px 8px 0px 0px #070707;
    }

    .workshops2 {
        padding-top: 5%;
        padding-left: 15%;

    }

    .topRight {
        display: none;
    }

    .topRightSmall {
        width: 343px;
        height: 455px;
        border-radius: 30px;
        border: 2px solid #000;
        background: #E2F6F6;
        margin-bottom: 30px;
    }

    .topRightSmall:hover {
        box-shadow: 0px 8px 0px 0px #070707;
    }

    .career2 {
        padding-top: 5%;
        padding-left: 20%;
    }

    .bottomRight {
        display: none;
    }

    .bottomRightSmall {
        width: 343px;
        height: 455px;
        border-radius: 30px;
        border: 2px solid #000;
        background: #FFF;
        margin-bottom: 30px;
    }

    .bottomRightSmall:hover {
        box-shadow: 0px 8px 0px 0px #070707;
    }

    .wehack2 {
        padding-top: 5%;
        padding-left: 20%;
    }

}

.valuesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 110%;
}

.heading {
    /* BOX */
    box-sizing: border-box;
    text-align: center;
    padding: 10px 42px;
    border: 4px solid #000000;
    border-radius: 300px;
    order: 0;
    
    /* TEXT */
    font-size: 22px;
    font-weight: 600;
    margin: 0 auto;
}

.subHeading {
    position: absolute;
    width: 80%;
    max-width: 816px;
    height: auto;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    color: #000000;
    order: 1;
    flex-grow: 0;
    text-align: center;
    margin-top: 10%;
    padding-top: 10%;
    z-index: 3;
    line-height: 60px; /* 166.667% */
    letter-spacing: -0.72px;

}

.vector {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    padding-top: 110%;
    padding-left: 1%;
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.cardContainer {
    position: absolute;
    margin-top: 120%;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .topLeftSmall {
      display: none;
    }
    .bottomLeftSmall {
        display: none;
      }
    .bottomRightSmall {
        display: none;
    }
    .topRightSmall {
        display: none;
    }
    .cardContainer2 {
        display: none;
    }

  }
  
.topLeft {
    width: 609px;
    height: 651px;
    border: solid #000000;
    color: black;
    border-radius: 30px;
    border: 2px solid #000;
    background: #FFF;
    justify-content: center;
    align-items: center;
    /* within card spacing */
    padding-top: 40px;
    text-align: center;
    
    /* actual card spacing */
    margin-right: 31px;
    margin-bottom: 47px;
}

.bottomLeft {
    width: 612px;
    height: 454px;
    border-radius: 30px;
    border: 2px solid #000;
    background: #FFE8ED;
    justify-content: center;
    align-items: center;
}

.topRight {
    width: 577px;
    height: 474px;
    border-radius: 30px;
    border: 2px solid #000;
    background: #E2F6F6;
    margin-bottom: 48px;
}

.bottomRight {
    width: 577px;
    height: 670px;
    border-radius: 30px;
    border: 2px solid #000;
    background: #FFF;
}

.topLeft:hover, .bottomLeft:hover, .topRight:hover, .bottomRight:hover {
    box-shadow: 0px 8px 0px 0px #070707;
}

.title {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 41.5px; /* 103.75% */
    letter-spacing: -0.8px;
    margin-bottom: 31px;
}

.description {
    display: flex;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.5px; /* 182.5% */
    letter-spacing: -0.4px;
    width: 460px;
    padding-left: 80px;
    margin-bottom: 20px;
}

.workshops {
    padding-top: 37px;
    padding-left: 186px;
    padding-right: 186px;
}

.title2 {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 41.5px; /* 103.75% */
    letter-spacing: -0.8px;
    margin-bottom: 27px;
    margin-top: 27px;
    margin-left: 33px;
    width: 546px;
}

.description2 {
    display: flex;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.5px; /* 182.5% */
    letter-spacing: -0.4px;
    width: 460px;
    padding-left: 80px;
    margin-bottom: 20px;
}

.career {
    padding-top: 58px;
    padding-left: 186px;
    padding-right: 186px;
}

.title3 {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 41.5px; /* 103.75% */
    letter-spacing: -0.8px;
    margin-bottom: 21px;
    margin-top: 21px;
    margin-left: 13px;
    width: 546px;
}

.description3 {
    display: flex;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.5px; /* 182.5% */
    letter-spacing: -0.4px;
    width: 450px;
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 20px;
}

.hack {
    padding-top: 67px;
    padding-left: 86px;
    padding-right: 86px;
    padding-bottom: 21px;
}

.title4 {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 41.5px; /* 103.75% */
    letter-spacing: -0.8px;
    margin-bottom: 21px;
    margin-top: 21px;
    margin-left: 13px;
    width: 546px;
}

.description4 {
    display: flex;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.5px; /* 182.5% */
    letter-spacing: -0.4px;
    width: 460px;
    padding-left: 59px;
    margin-bottom: 20px;
}