@media screen and (max-width: 767px) {
  .card {
    display: none;
  }
}

  .card2 {
    width: 323px;
    height: 455px;
    border-radius: 30px;
    border: 4px solid #000;
    background: #FFF;
    margin-left: 15px;
    margin-right: 15px;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  
  .pic2 {
    /* old:
    width: 240px;
    height: 240px; */
    width: 200px;
    height: 200px;
    margin-left: 40px;
    margin-top: 35px;
    border-radius: 30px;
    border: 1px solid #000;
    z-index: 4;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .cardTitle2 {
    color: #000;
    text-align: center;
    /*old: font-size: 20px; */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    /* old:
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    */
    /*new*/
    height: auto; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    white-space: normal; 
    padding: 0 10px; 
    margin: 10px 0 20px 0; 
  }

  .cardSubtitle2 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
    text-transform: capitalize;
    text-align: center;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .addCalendar2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }


@media screen and (min-width: 768px) {
  .card2 {
    display: none;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 390px;
    height: 700px; /* adjusted to fit new insta 4:5 dimensions */
    background-color: white;
    border: #000000 2px solid;
    border-radius: 15px;
    font-size: 15px; 
    margin: 20px; 
    padding: 10px;
  }
}
  
  .pic {
    /*old: width: 350px;*/
    width: 300px;
    margin-top: 5%;
    margin-left: 4%;
    /*old: height: 350px;*/
    height: 300px;
    border-radius: 30px;
    border: 2px solid #000;
    z-index: 4;
  }
  
  .cardTitle {
    text-align: center; 
    font-weight: 700;
    /* old:
    font-size: 30px;
    height: 35px; */
    font-size: 24px;
    height: auto;
    /*new*/
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    white-space: normal; 
    padding: 0 10px; 
    margin: 10px 0 20px 0;
  }
  
  .cardSubtitle {
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    height: 25px;
    color: black;
    margin-top: -5%;
  }
  
  .addCalendar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: -3%;
    padding-bottom: 5%;
  }
  